import React from 'react'
import { Link } from 'gatsby'
import { List, ListItem, ListItemText, Divider } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'

import Collapse from '../Collapse'
import { StyledDrawer } from './styles'

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />
}

export default function Drawer(props) {
  return (
    <StyledDrawer
      anchor="right"
      open={props.isDrawerOpen}
      onClose={props.toggleDrawer}
    >
      <CloseRounded fontSize="large" onClick={props.toggleDrawer} />
      <Divider />
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink to="/ead/pos-direito">
          <ListItemText primary="Cursos de direito" />
        </ListItemLink>
        <Collapse
          head="Graduação"
          items={[
            { name: 'Presencial na Faculdade Jardins - Aracaju', to: '/presencial/graduacao' },
            // { name: 'Semipresencial', to: '/semipresencial/graduacao' },
            { name: 'EAD', to: '/ead/graduacao' },
            { name: 'Presencial na Faculdade Jardins ALL - Lagarto', to: '/presencial/all-lagarto' },
            // { name: 'Segunda Graduação', to: '/segunda-graduacao' },
          ]}
        />
        <Collapse
          head="Pós-Graduação"
          items={[
            { name: 'EAD', to: '/ead/pos-graduacao' },
            // { name: 'Semipresencial', to: '/semipresencial/pos-graduacao' },
            { name: 'Presencial', to: '/presencial/pos-graduacao' },
          ]}
        />
        <ListItemLink to="/extensao">
          <ListItemText primary="Extensão" />
        </ListItemLink>
        {/* <Collapse
          head="Curso Técnico"
          items={[
            { name: 'EAD', to: '/curso-tecnico/ead' },
            { name: 'Presencial', to: '/curso-tecnico/presencial' },
          ]}
        /> */}
        <Collapse
          head="Institucional"
          items={[
            { name: 'Matrícula Presencial', to: 'https://faculdadejardins-com-br.rds.land/ld-camp-de-matriculas-23-24', type: 'external' },
            { name: 'Matrícula EaD', to: 'https://faculdadejardins-com-br.rds.land/ld-camp-de-matriculas-23-24', type: 'external' },
            { name: 'Sistema Acadêmico', to: 'https://app12.activesoft.com.br/sistema/entrar.asp?p=IES_FACJARDINS', type: 'external' },
            { name: 'Perguntas e Respostas', to: '/faq' },
            { name: 'Documentos Institucionais', to: '/documentos' },
            { name: 'NAE - Núcleo de apoio ao estudante', to: '/nae' },
            { name: 'Diplomas registrados', to: '/diplomas-registrados' },
            { name: 'Consulta ao Registro de Diploma', to: 'https://dd.diplomax.cloud/?codigovalidacao=', type: 'external' },
            { name: 'Comissão própria de avaliação (CPA)', to: '/cpa' },
            { name: 'Nossa História', to: '/nossa-historia' },
          ]}
        />
        <Collapse
          head="Documentos"
          items={[
            { name: 'Carteira Estudantil', to: 'https://faculdadejardins-com-br.rds.land/ld-camp-de-matriculas-23-24', type: 'external' },
            { name: 'Plano de Ensino do Aluno', to: 'https://pag.ae/7Wj5FLUVs', type: 'external' },
          ]}
        />
        <ListItemLink to="/vestibular">
          <ListItemText primary="Vestibular" />
        </ListItemLink>
        <ListItemLink to="/pesquisa">
          <ListItemText primary="Pesquisa e Inovação" />
        </ListItemLink>
        <ListItemLink to="/">
          <ListItemText primary="Eventos" />
        </ListItemLink>
      </List>
    </StyledDrawer>
  )
}
